export default {
  ACCESS_TOKEN_KEY: 'accessToken',

  ANNOUNCEMENT_DISMISSED: 'dismissed',

  EMAIL_KEY: 'email',

  ENTERPRISE_EXTERNAL_ID_KEY: 'enterprise-external-id',

  ENTERPRISE_WELCOME_MODAL: 'enterprise-welcome-modal',

  ENTERPRISE_ELIGIBILITY_MODAL: 'enterprise-eligibility-modal',

  EXPLORE_CHALLENGE_KEY_PREFIX: 'explore-challenge',

  FAVORITES_ANNOUNCEMENT_KEY: 'favorites-announcement',

  FB_CLID: 'fbclid', // facebook click id

  FIRST_LESSON_REMINDER_KEY: 'first-lesson-reminder',

  FROM_APP: 'from-app',

  G_CLID: 'gclid', // google click id

  HOME_KEY: 'landing',
  HOME_ARTHRITIS: 'arthritis',
  HOME_BALANCED: 'balanced',
  HOME_SPECIAL: 'special',
  HOME_ORCA: 'orca',
  HOME_REEF: 'reef',

  MASS_ADVANTAGE: 'mass-advantage',

  MODAL_DISMISSED: 'dismissed',
  MODAL_SHOWN: 'shown',

  PHONE_NUMBER_MODAL_KEY: 'phone-number',

  MOBILE_APP_DOWNLOAD_MODAL_KEY: 'mobile-app-download',

  PROMO_CODE_KEY: 'promo-code',

  PROMO_CODE_COUPON_KEY: 'PROMO_CODE_COUPON',

  REFRESH_TOKEN_KEY: 'refreshToken',

  SKILL_BUILDING_KEY: 'skill-building',

  TODAYS_PLAN_4_2: 'todays-plan-4-2',

  UID_KEY: 'uid',

  UTM_ADGROUP: 'utm_adgroup',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',

  VISITOR_ID_KEY: 'visitor-id',
}
